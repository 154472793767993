<div class="dash_base">
  <div class="loadingMapFull" *ngIf="isLoadingDealList">
    <div class="flex_center fullHeight">
      <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load
    </div>
  </div>
</div>

<mat-sidenav-container [ngStyle]="{ 'z-index': isPropertySheetOpened ? 1 : 10 }">
  <mat-sidenav
    class="main row deal_analysis"
    #sidenav
    mode="over"
    position="end"
    [opened]="dealAnalysisSheetFacade.isSheetOpen() | async"
    disableClose
  >
    <div class="sheet-header">
      <h4>{{ dealSheetHeaderName }}</h4>
      <button type="button" class="prop_dtls_closeBtn float-right" (click)="closeDealAnalysisSheet()">
        <img src="../../../../assets/images/close.svg" />
      </button>
      <div class="btn-group float-right mr-md-5" *ngIf="isDealOwned">
        <button
          *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
          type="button"
          color="primary"
          mat-flat-button
          class="pt-0 save-btn"
          (click)="saveDeal('save')"
        >
          <span class="d-sm-inline-block pl-1">Save</span>
        </button>
        <div
          class="btn-group"
          ngbDropdown
          role="group"
          aria-label="Button group with nested dropdown"
          style="height: 29px"
          *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
        >
          <button class="btn btn-primary pt-0 dropdown-toggle-split" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button mat-flat-button color="primary" (click)="openDialog('save')" class="w-100">
              <mat-icon>save</mat-icon>
              Save As
            </button>
          </div>
        </div>
      </div>
      <button type="button" mat-stroked-button class="float-right mr-3 icon-btn" (click)="reset()">
        <mat-icon inline>replay</mat-icon>
      </button>
      <button
        type="button"
        mat-stroked-button
        class="float-right mr-3 icon-btn"
        *ngIf="isDealOwned"
        (click)="shareDeal()"
      >
        <mat-icon inline>share</mat-icon>
      </button>
    </div>
    <div class="sub-header">
      <div class="row">
        <div class="col-md-1 col-sm-1 col-12 d-grid">
          <img
            class="mb-0 img-fluid"
            [src]="
              listingImageBaseUrl + '?ListingId=' + (activePropertyDetails?.Lst_ListingId || 'null') + '&imageName=1'
            "
            style="height: 52px; margin: auto"
          />
        </div>
        <div class="col-md-4 col-sm-3 col-12 d-grid pl-md-0">
          <div class="ads-div">
            <h5
              class="mb-0"
              (click)="openingPropertySheet()"
              *ngIf="activePropertyDetails?.propertyAddress"
              [propertyAddress]="activePropertyDetails.Address"
            >
              {{ activePropertyDetails.propertyAddress || activePropertyDetails.StreetAddress | IsValueExist }}<br />
              <span
                (click)="openingPropertySheet()"
                class="mb-0 d-inline-block sub-ads"
                *ngIf="activePropertyDetails?.City"
                [propertyAddress]="activePropertyDetails.Address"
                >{{ activePropertyDetails.City ? activePropertyDetails?.City : '' }}
                {{ activePropertyDetails.StateOrProvince ? ', ' + activePropertyDetails?.StateOrProvince : '' }}
                {{ activePropertyDetails.PostalCode ? ' ' + activePropertyDetails?.PostalCode : '' }}</span
              >
            </h5>
            <mat-chip class="applied-filter ml-2">
              {{ activePropertyDetails?.PropertySubType }}
            </mat-chip>
          </div>
        </div>
        <div class="col-md-7 col-sm-8 col-12 m-value">
          <span class="prop-hlt">
            <ul class="prop_highlight pl-2 mb-0 mr-2">
              <li [matTooltip]="'Bed'">
                <img src="../../assets/images/bed.svg" width="24" />
                <p class="ipad_amn_break ml-1">
                  {{ activePropertyDetails?.BedroomsTotal | number: '1.0-0' | IsValueExist }}
                </p>
              </li>
              <li [matTooltip]="'Bath'">
                <img src="../../assets/images/bath.svg" width="24" />
                <p class="ipad_amn_break ml-1">
                  {{ activePropertyDetails?.BathroomsTotalInteger | number: '1.0-0' | IsValueExist }}
                </p>
              </li>
              <li [matTooltip]="'Living Area'">
                <img src="../../assets/images/living_area.svg" width="24" />
                <p class="ipad_amn_break ml-1">
                  {{ activePropertyDetails?.LivingArea | number: '1.0-0' | IsValueExist }}<small> Sq.ft</small>
                </p>
              </li>
              <li [matTooltip]="'Lot Size Square Feet'">
                <img src="../../assets/images/land_area.svg" width="24" />
                <p
                  class="ipad_amn_break ml-1"
                  [innerHTML]="activePropertyDetails?.LotSizeSquareFeet | SqftToAcresConverter"
                ></p>
              </li>
            </ul>
            <div class="pr-2 pl-0 pl-sm-2">
              <span>Current Value</span><br />
              <h4 class="mb-0 mc-value">
                <!-- <b
                  *ngIf="
                    (activeAVMHistory && activeAVMHistory.length > 0) || activeHomeValue?.HomeValue;
                    else noActiveAVMHistory
                  "
                  [innerHTML]="
                    activeHomeValue?.HomeValue || activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist
                  "
                ></b> -->
                <b
                  *ngIf="activeAVMHistory && activeAVMHistory.length > 0; else noActiveAVMHistory"
                  [innerHTML]="activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist"
                ></b>
                <ng-template #noActiveAVMHistory>
                  <b>--</b>
                </ng-template>
              </h4>
            </div>
            <div class="pr-2 pl-2">
              <span>Rent Value</span><br />
              <h4 class="mr-value">
                <span
                  ><b>{{ activePropertyDetails?.RentValue | CurrencyFormatter | IsValueExist }}</b></span
                >
              </h4>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="sheet-content">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-12 mb-4">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-0">Income Evaluation</h5>
                  </div>
                  <div class="col-6" *ngIf="!showIncomeDetails">
                    <button mat-icon-button (click)="toggleIncomeDetails()">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="panel-ht">
              <!-- <div class="row" *ngIf="showIncomeDetails">
                <div class="col-6">
                  <span>Cap Rate</span>
                  <h5 class="mt-1">
                    {{
                      propertyInfo?.CapRate || activePropertyDetails?.CapRate
                        ? (propertyInfo?.CapRate || activePropertyDetails?.CapRate) + '%'
                        : '--'
                    }}
                  </h5>
                </div>

                <div class="col-6">
                  <span>Cash On Cash</span>
                  <h5
                    class="mt-1"
                    *ngIf="computedDealAnalysisResult && computedDealAnalysisResult.cashOnCashReturnRatio"
                  >
                    {{
                      computedDealAnalysisResult.cashOnCashReturnRatio.length > 0
                        ? (computedDealAnalysisResult.cashOnCashReturnRatio[0] * 100).toFixed(2) + '%'
                        : '--'
                    }}
                  </h5>
                </div>
              </div> -->

              <!-- table and button -->

              <div class="row">
                <div class="col-12">
                  <div class="chart-img">
                    <button (click)="toggleChart('incomeEvaluation')">
                      <img src="../../../../assets/images/chart-button.png" alt="view graph" />
                    </button>
                  </div>
                  <div class="table-responsive">
                    <table class="table mtable income-table">
                      <thead>
                        <tr>
                          <th class="border1">&nbsp;</th>
                          <th class="border1">Monthly</th>
                          <th>Annual</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border1">Rental Income</td>
                          <td class="border1 fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.monthlyRent &&
                                computedDealAnalysisResult.monthlyRent[0]
                              "
                            >
                              {{
                                !isNumber(computedDealAnalysisResult.monthlyRent[0])
                                  ? computedDealAnalysisResult.monthlyRent[0] || '--'
                                  : (computedDealAnalysisResult.monthlyRent[0] | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.monthlyRent &&
                                computedDealAnalysisResult.monthlyRent[0]
                              "
                            >
                              {{
                                !isNumber(computedDealAnalysisResult.monthlyRent[0])
                                  ? computedDealAnalysisResult.monthlyRent[0] || '--'
                                  : (computedDealAnalysisResult.monthlyRent[0] * 12 | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="border1">Expenses</td>
                          <td class="border1 fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.operatingExpense &&
                                computedDealAnalysisResult.operatingExpense[0]
                              "
                            >
                              {{
                                !isNumber(computedDealAnalysisResult.operatingExpense[0])
                                  ? computedDealAnalysisResult.operatingExpense[0] || '--'
                                  : (computedDealAnalysisResult.operatingExpense[0] / 12
                                    | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.operatingExpense &&
                                computedDealAnalysisResult.operatingExpense[0]
                              "
                              >{{
                                !isNumber(computedDealAnalysisResult.operatingExpense[0])
                                  ? computedDealAnalysisResult.operatingExpense[0] || '--'
                                  : (computedDealAnalysisResult.operatingExpense[0] | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="border1">Net Operating Income</td>
                          <td class="border1 fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.netOperatingIncome &&
                                computedDealAnalysisResult.netOperatingIncome[0]
                              "
                            >
                              {{
                                !isNumber(computedDealAnalysisResult.netOperatingIncome[0])
                                  ? computedDealAnalysisResult.netOperatingIncome[0] || '--'
                                  : (computedDealAnalysisResult.netOperatingIncome[0] / 12
                                    | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.netOperatingIncome &&
                                computedDealAnalysisResult.netOperatingIncome[0]
                              "
                              >{{
                                !isNumber(computedDealAnalysisResult.netOperatingIncome[0])
                                  ? computedDealAnalysisResult.netOperatingIncome[0] || '--'
                                  : (computedDealAnalysisResult.netOperatingIncome[0]
                                    | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="border1">Mortgage Payment</td>
                          <td class="border1 fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.mortgagePayment &&
                                computedDealAnalysisResult.mortgagePayment[0]
                              "
                            >
                              {{
                                !isNumber(computedDealAnalysisResult.mortgagePayment[0])
                                  ? computedDealAnalysisResult.mortgagePayment[0] || '--'
                                  : (computedDealAnalysisResult.mortgagePayment[0] / 12
                                    | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.mortgagePayment &&
                                computedDealAnalysisResult.mortgagePayment[0]
                              "
                              >{{
                                !isNumber(computedDealAnalysisResult.mortgagePayment[0])
                                  ? computedDealAnalysisResult.mortgagePayment[0] || '--'
                                  : (computedDealAnalysisResult.mortgagePayment[0] | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td class="border1">Cash Flow</td>
                          <td class="border1 fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.cashFlow &&
                                computedDealAnalysisResult.cashFlow[0]
                              "
                              >{{
                                !isNumber(computedDealAnalysisResult.cashFlow[0])
                                  ? computedDealAnalysisResult.cashFlow[0] || '--'
                                  : (computedDealAnalysisResult.cashFlow[0] / 12 | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="
                                computedDealAnalysisResult &&
                                computedDealAnalysisResult.cashFlow &&
                                computedDealAnalysisResult.cashFlow[0]
                              "
                            >
                              {{
                                !isNumber(computedDealAnalysisResult.cashFlow[0])
                                  ? computedDealAnalysisResult.cashFlow[0] || '--'
                                  : (computedDealAnalysisResult.cashFlow[0] | currency: 'USD':'symbol':'1.0-0')
                              }}
                            </span>
                          </td>
                        </tr>
                        <!-- <cap rate></cap> -->
                        <tr>
                          <td class="border1">Cap Rate</td>
                          <td class="border1 fw-600">
                            <span *ngIf="showIncomeDetails"> -- </span>
                          </td>
                          <td class="fw-600">
                            <span *ngIf="showIncomeDetails">
                              {{
                                propertyInfo?.CapRate || activePropertyDetails?.CapRate
                                  ? (propertyInfo?.CapRate || activePropertyDetails?.CapRate) + '%'
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>

                        <!-- <cash on cash></cash> -->
                        <tr>
                          <td class="border1">Cash On Cash</td>
                          <td class="border1 fw-600">
                            <span
                              *ngIf="computedDealAnalysisResult && computedDealAnalysisResult.cashOnCashReturnRatio"
                            >
                              --
                            </span>
                          </td>
                          <td class="fw-600">
                            <span
                              *ngIf="computedDealAnalysisResult && computedDealAnalysisResult.cashOnCashReturnRatio"
                            >
                              {{
                                computedDealAnalysisResult.cashOnCashReturnRatio.length > 0
                                  ? (computedDealAnalysisResult.cashOnCashReturnRatio[0] * 100).toFixed(2) + '%'
                                  : '--'
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- chart -->
              <div class="row mt-3" *ngIf="showIncomeEvalChart">
                <!-- <div class="my-3" *ngIf="returnIndicatorSummary && returnIndicatorSettings"> -->
                <app-line-chart
                  [chartData]="returnIndicatorSummary"
                  [chartSettings]="returnIndicatorSettings"
                  *ngIf="returnIndicatorSummary && returnIndicatorSettings"
                >
                </app-line-chart>
              </div>
            </div>
          </mat-expansion-panel>
        </div>

        <!-- //equity evaluation -->

        <div class="col-md-6 col-sm-12 col-12 mb-4">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-0">Equity Evaluation</h5>
                  </div>
                  <div class="col-6" *ngIf="!showEquityDetails">
                    <button mat-icon-button (click)="toggleEquityDetails()">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-ht">
              <div class="row" *ngIf="showEquityDetails">
                <div class="col-8">
                  <h5 class="mt-3">Years to Profit : {{ profitOnSaleYear }} years</h5>
                </div>
                <div class="col-4">
                  <div class="chart-img">
                    <button (click)="toggleChart('equityEvaluation')">
                      <img src="../../../../assets/images/chart-button.png" alt="view graph" />
                    </button>
                  </div>
                </div>
                <!-- <div class="col-6">
                  <span>Achieve Profit On Sale by</span>
                  <h5 class="mt-1">{{ profitOnSaleYear }} Years</h5>
                </div> -->
              </div>
              <!-- <div class="row mt-3 mb-md-3">
                <div class="col-6">
                  <span>Equity After {{ profitOnSaleYear }} Years</span>
                  <h5 class="mt-1">
                    {{ !isNumber(equity) ? equity : (equity | currency: 'USD':'symbol':'1.0-0') }}
                  </h5>
                </div>
                <div class="col-6">
                  <span>Profit On Sale After {{ profitOnSaleYear }} Years</span>
                  <h5 class="mt-1">
                    {{ !isNumber(profitOnSale) ? profitOnSale : (profitOnSale | currency: 'USD':'symbol':'1.0-0') }}
                  </h5>
                </div>
              </div> -->

              <div class="table-responsive">
                <table class="table mtable income-table">
                  <thead>
                    <tr>
                      <th class="border1">&nbsp;</th>
                      <th class="border1">After {{ profitOnSaleYear }} Years</th>
                      <th>After {{ upcompingEquityEval }} years</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border1">Total Invested</td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(totalCashInvested)
                              ? totalCashInvested || '--'
                              : (totalCashInvested | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingTotalCashInvested)
                              ? upcomingTotalCashInvested || '--'
                              : (upcomingTotalCashInvested | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="border1">Your Equity</td>
                      <td class="border1 fw-600">
                        <span>
                          {{ !isNumber(equity) ? equity || '--' : (equity | currency: 'USD':'symbol':'1.0-0') }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingEquity)
                              ? upcomingEquity || '--'
                              : (upcomingEquity | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">Property Value</td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(propertyValue)
                              ? propertyValue || '--'
                              : (propertyValue | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span
                          >{{
                            !isNumber(upcomingPropertyValue)
                              ? upcomingPropertyValue || '--'
                              : (upcomingPropertyValue | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">Mortgage Balance</td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(mortgageBalance)
                              ? mortgageBalance || '--'
                              : (mortgageBalance | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span
                          >{{
                            !isNumber(upcomingMortgageBalance)
                              ? upcomingMortgageBalance || '--'
                              : (upcomingMortgageBalance | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">Net Proceeds</td>
                      <td class="border1 fw-600">
                        <span
                          >{{
                            !isNumber(netProceeds)
                              ? netProceeds || '--'
                              : (netProceeds | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingNetProceeds)
                              ? upcomingNetProceeds || '--'
                              : (upcomingNetProceeds | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">Profit On Sale</td>
                      <td class="border1 fw-600">
                        <span>
                          {{
                            !isNumber(profitOnSale)
                              ? profitOnSale || '--'
                              : (profitOnSale | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{
                            !isNumber(upcomingProfitonSale)
                              ? upcomingProfitonSale || '--'
                              : (upcomingProfitonSale | currency: 'USD':'symbol':'1.0-0')
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="border1">ROI</td>
                      <td class="border1 fw-600">
                        <span>
                          {{ returnOnInvestment ? (returnOnInvestment * 100).toFixed(2) + '%' : '--' }}
                        </span>
                      </td>
                      <td class="fw-600">
                        <span>
                          {{ upcomingReturnOnInvestment ? (upcomingReturnOnInvestment * 100).toFixed(2) + '%' : '--' }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row mt-3" *ngIf="showEquityEvalChart">
                <app-line-chart
                  [chartData]="salePredictionSummary"
                  [chartSettings]="salePredictionSettings"
                  *ngIf="salePredictionSummary && salePredictionSettings"
                >
                </app-line-chart>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-sm-12 col-12">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-0">Income Analysis</h5>
                  </div>
                  <div class="col-6" *ngIf="!showIncomeDetails">
                    <button mat-icon-button (click)="toggleIncomeDetails()">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="dealAnalysisForm">
              <mat-tab-group class="analysis-tab">
                <mat-tab>
                  <ng-template mat-tab-label>Purchase & Renovation</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Purchase Price
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.purchasePriceInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uPurchasePrice"
                              placeholder="Price"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPurchasePrice?.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Down Payment
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.downPaymentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uDownPayment"
                              placeholder="Price(%)"
                              mask="separator.2"
                              suffix=" %"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uDownPayment.errors || downPaymentError" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never"
                            ><input
                              matInput
                              autocomplete="off"
                              placeholder="Price($)"
                              formControlName="uDownPaymentInDollors"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uDownPaymentInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4"> -->
                    <!-- <div class="row"> -->
                    <!-- <div class="col-md-4 col-sm-3 col-2">
                          <select class="payment_type mt-3" formControlName="uDownPaymentType">
                            <option *ngFor="let downPaymentType of downPaymentTypeList" [ngValue]="downPaymentType">
                              {{ downPaymentType }}
                            </option>
                          </select>
                        </div> -->
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> Down Payment In % </mat-label> -->
                    <!-- <span matPrefix>
                          <select class="payment_type" formControlName="uDownPaymentType">
                            <option *ngFor="let downPaymentType of downPaymentTypeList" [ngValue]="downPaymentType">
                              {{ downPaymentType }}
                            </option>
                          </select>
                        </span> -->
                    <!-- <input
                          matInput
                          autocomplete="off"
                          placeholder="Down Payment"
                          formControlName="uDownPayment"
                          suffix="%"
                        />
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.downPaymentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-form-field>
                      <mat-error *ngIf="f?.uDownPayment.errors || downPaymentError" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->

                    <!-- </div> -->

                    <!-- </div> -->
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label>Down Payment In $ </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="Down Payment In $"
                          formControlName="uDownPaymentInDollors"
                          mask="separator.2"
                          prefix="$"
                        />
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.landValueInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-form-field>
                      <mat-error *ngIf="f?.uDownPaymentInDollors.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Land Value
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.landValueInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uLandValue"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uLandValue.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Closing Costs
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.closingCostsInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uClosingCost"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uClosingCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Renovation Costs
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.renovationCostsInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uRenovationCost"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uRenovationCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Mortgage Information</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Loan Amount
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.loanAmountInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uLoanAmount"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uLoanAmount.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Interest Rate
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.interestRateInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uInterestRate"
                              mask="separator.2"
                              suffix=" %"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uInterestRate.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Term
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.termInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uLoanTerm"
                              mask="separator.2"
                              suffix=" yrs"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uLoanTerm.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Monthly Mortgage Payment
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.monthlyMortgagePaymentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uMonthlyMortgagePayment"
                              mask="separator.2"
                              suffix="$"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Annual Expenses</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Property Taxes
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uPropertyTaxAmount"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPropertyTaxAmount.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Insurance
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uInsuranceAmount"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uInsuranceAmount.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> HOA Fee </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="HOA Fee"
                              formControlName="uHoaFeeYoYPercent"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> HOA Fee YoY % </mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input matInput autocomplete="off" placeholder="YoY %" formControlName="uHoaFeeYoYPercent"
                          /></mat-form-field>
                          <mat-error *ngIf="f?.uHoaFeeYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> HOA Fee YoY % </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="HOA Fee YoY %"
                          formControlName="uHoaFeeYoYPercent"
                        />
                      </mat-form-field>
                      <mat-error *ngIf="f?.uHoaFeeYoYPercent.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Property Management
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uPropertyManagementPercent"
                              mask="separator.2"
                              suffix=" %"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPropertyManagementPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uPropertyManagementInDollors"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uPropertyManagementInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> Property Management in $ </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="Property Management in $"
                          formControlName="uPropertyManagementInDollors"
                          mask="separator.2"
                          prefix="$"
                        />
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.salesCommissionInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-form-field>
                      <mat-error *ngIf="f?.uPropertyManagementInDollors.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Repairs & Maintenance
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value(%)"
                              formControlName="uMaintenancePercent"
                              mask="separator.2"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMaintenancePercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uMaintenanceInDollors"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMaintenanceInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> Repairs & Maintenance in $ </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="Repairs & Maintenance"
                          formControlName="uMaintenanceInDollors"
                          mask="separator.2"
                          prefix="$"
                        />
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.salesCommissionInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-form-field>
                      <mat-error *ngIf="f?.uMaintenanceInDollors.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Improvements
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.improvementsInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              formControlName="uImprovementPercent"
                              mask="00000"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uImprovementPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Warranty Service
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uWarrantyServiceCost"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uWarrantyServiceCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>Warranty Service YoY%</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YoY %"
                              formControlName="uWarrantyServiceYoYPercent"
                              mask="00000"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uWarrantyServiceYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Accounting
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uAccountingCost"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAccountingCost.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> Accounting YoY %</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YoY %"
                              formControlName="uAccountingYoYPercent"
                              mask="00000"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAccountingYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> Accounting YoY % </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="Accounting YoY %"
                          formControlName="uAccountingYoYPercent"
                        />
                      </mat-form-field>
                      <mat-error *ngIf="f?.uAccountingYoYPercent.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Revenue</ng-template>
                  <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Monthly Rent
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uMonthlyRent"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMonthlyRent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> Monthly Rent YoY %</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YoY %"
                              formControlName="uMonthlyRentYoYPercent"
                              mask="separator.2"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uMonthlyRentYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> Monthly Rent YoY % </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="Monthly Rent YoY %"
                          formControlName="uMonthlyRentYoYPercent"
                        />
                      </mat-form-field>
                      <mat-error *ngIf="f?.uMonthlyRentYoYPercent.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Renter Churn
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.rentChurnInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Renter Churn"
                              formControlName="uRenterChurn"
                              mask="separator.2"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uRenterChurn.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        New Tenant Search Time
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.newTenantSearchTimeInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Time"
                              formControlName="uTenantSearchDuration"
                              mask="separator.2"
                              suffix=" Months"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uTenantSearchDuration.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Value</ng-template>
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Assessed Property Value
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.assessedPropertyValueInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uAssessedPropertyValue"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAssessedPropertyValue.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>YoY%</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YoY %"
                              formControlName="uAssessedPropertyValueYoYPercent"
                              mask="00000"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uAssessedPropertyValueYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> YoY % </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="YoY %"
                          formControlName="uAssessedPropertyValueYoYPercent"
                        />
                      </mat-form-field>
                      <mat-error *ngIf="f?.uAssessedPropertyValueYoYPercent.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Fair Market Value
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.fairMarketValueInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uFairMarketValue"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uFairMarketValue.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-label> YoY %</mat-label>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="YoY %"
                              formControlName="uFairMarketValueYoYPercent"
                              mask="separator.2"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uFairMarketValueYoYPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> YoY % </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="YoY %"
                          formControlName="uFairMarketValueYoYPercent"
                        />
                      </mat-form-field>
                      <mat-error *ngIf="f?.uFairMarketValueYoYPercent.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>Sale</ng-template>
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-12 mt-4">
                      <mat-label>
                        Sales Commission
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.salesCommissionInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        ></mat-label
                      >
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6 pr-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Sales Commission"
                              formControlName="uSalesCommissionPercent"
                              mask="separator.2"
                              suffix="%"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uSalesCommissionPercent.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 pl-2">
                          <mat-form-field class="w-100" floatLabel="never">
                            <input
                              matInput
                              autocomplete="off"
                              placeholder="Value($)"
                              formControlName="uSalesComissionInDollors"
                              mask="separator.2"
                              prefix="$"
                            />
                          </mat-form-field>
                          <mat-error *ngIf="f?.uSalesComissionInDollors.errors" class="invalid-feedback ml-0"
                            >{{ errorMessage }}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-2 col-sm-6 col-12 mt-4">
                      <mat-form-field class="w-100">
                        <mat-label> Sales Commission in $ </mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          placeholder="Sales Commission"
                          formControlName="uSalesComissionInDollors"
                          mask="separator.2"
                          prefix="$"
                        />
                        <mat-icon
                          matSuffix
                          [matTooltip]="dealAnalysisConfig.tooltipText.salesCommissionInput"
                          matTooltipClass="line-break"
                          [matTooltipPosition]="'right'"
                          class="help-icon"
                          >help_outline</mat-icon
                        >
                      </mat-form-field>
                      <mat-error *ngIf="f?.uSalesComissionInDollors.errors" class="invalid-feedback ml-0"
                        >{{ errorMessage }}
                      </mat-error>
                    </div> -->
                  </div>
                </mat-tab>
              </mat-tab-group>

              <div class="row">
                <div class="col-md-12 col-sm-12 col-12">
                  <div class="section-middle">
                    <div class="section-data mt-0">
                      <div class="table-data mt-4">
                        <table class="data-table table-header">
                          <tr>
                            <td></td>
                            <td
                              [class.expanded-col]="!dealAnalysisSheetFacade.defaultYearList.includes(year)"
                              *ngFor="let year of dealAnalysisSheetFacade.displayYearList$ | async"
                            >
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded10"
                                *ngIf="year == 10 || year == 6"
                                (click)="toggleYear(10, !isExpanded10)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded15"
                                *ngIf="year == 15 || year == 11"
                                (click)="toggleYear(15, !isExpanded15)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded20"
                                *ngIf="year == 20 || year == 16"
                                (click)="toggleYear(20, !isExpanded20)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              <span
                                class="btn-year btn-expand"
                                [class.shrink]="isExpanded30"
                                *ngIf="year == 30 || year == 21"
                                (click)="toggleYear(30, !isExpanded30)"
                              >
                                <mat-icon>arrow_left</mat-icon>
                                <mat-icon>arrow_right</mat-icon>
                              </span>
                              Year {{ year }}
                            </td>
                          </tr>
                        </table>

                        <mat-accordion class="compute-table" [multi]="true">
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Revenue </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.grossRentInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Gross Rent
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.grossRent">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Monthly Rent
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.monthlyRent">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.vacancyInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Vacancy
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.vacancy">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Income
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.operatingIncome"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Expenses </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Property Taxes
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.propertyTax">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Insurance
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.insurance">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.hoaFeeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      HOA Fee
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.hoaFee">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Property Management
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.propertyManagement">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Repairs & Maintenance
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.maintenance">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Warranty Service
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.warrantyService">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Accounting
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.accounting">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Expenses
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.operatingExpense"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Cash Flow </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Income
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.operatingIncome">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Expenses
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.netOperatingIncomeInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Net Operating Income
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.netOperatingIncome">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>Mortgage Payment</td>
                                    <td *ngFor="let data of computedDealAnalysisResult.mortgagePayment">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.cashFlowInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cash Flow
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.cashFlow"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Tax Deductions </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Operating Expenses
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.mortgageInterestInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Mortgage Interest
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.mortgageInterest">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.depreciationInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Depreciation
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.depreciation">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.taxDeductionInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Total Deductions
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.totalDeduction"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>Taxable Income</td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.taxableIncome"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Equity Appreciation </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.propertyValueInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Property Value
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.propertyValue">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>Mortgage Balance</td>
                                    <td *ngFor="let data of computedDealAnalysisResult.mortgageBalance">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Your Equity
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.equity"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> When Should I Sell? </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Your Equity
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.equity">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.sellingCostsInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Selling Costs
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.sellingCost">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.cumulativeCashFlowInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cumulative Cash Flow
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.cumulativeCashFlow">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.totalCashInvestedInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Total Cash Invested
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.totalCashInvested">
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.netProceedsInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Net Proceeds
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.netProceed"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.profitOnSaleInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Profit On Sale
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.profitOnSale"
                                    >
                                      {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                                    </td>
                                  </ng-container>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div class="compute-section">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title> Return Indicators </mat-panel-title>
                              </mat-expansion-panel-header>
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnPurchasePriceInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cap Rate on Purchase Price
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.capRateOnPurchasePriceRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnFMVInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cap Rate on FMV
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.capRateOnFMVRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.cashOnCashReturnInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Cash on Cash Return
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.cashOnCashReturnRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.returnOnInvestmentInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Return on Investment
                                    </td>
                                    <td *ngFor="let data of computedDealAnalysisResult.returnOnInvestmentRatio">
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                              </table>
                            </mat-expansion-panel>
                            <div class="group-footer">
                              <table class="data-table">
                                <tr>
                                  <ng-container>
                                    <td>
                                      <mat-icon
                                        [matTooltip]="dealAnalysisConfig.tooltipText.returnOnEquityInput"
                                        [matTooltipPosition]="'right'"
                                        matTooltipClass="line-break"
                                      >
                                        help_outline</mat-icon
                                      >
                                      Return on Equity
                                    </td>
                                    <td
                                      [class.text-danger]="isNumber(data) && data < 0"
                                      *ngFor="let data of computedDealAnalysisResult.returnOnEquityRatio"
                                    >
                                      {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                                    </td></ng-container
                                  >
                                </tr>
                              </table>
                            </div>
                          </div>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
    <!-- Arya's code start -->
    <!-- <div class="sheet-header">
      <div class="row">
        <div class="col-6">
          <h4>{{ dealSheetHeaderName }}</h4>
        </div>
        <div class="col-6">
          <button type="button" mat-stroked-button *ngIf="isDealOwned" (click)="shareDeal()">
            <mat-icon inline>share</mat-icon>
          </button>
          <button type="button" mat-stroked-button (click)="reset()">
            <mat-icon inline>replay</mat-icon>
          </button>
          <div class="btn-group" *ngIf="isDealOwned">
            <button
              *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
              type="button"
              color="primary"
              mat-flat-button
              class="pt-0 save-btn"
              (click)="saveDeal('save')"
            >
              <span class="d-none d-sm-inline-block pl-1">Save</span>
            </button>
            <div
              class="btn-group"
              ngbDropdown
              role="group"
              aria-label="Button group with nested dropdown"
              style="height: 29px"
              *ngIf="companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')"
            >
              <button class="btn btn-primary pt-0 dropdown-toggle-split" ngbDropdownToggle></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button mat-flat-button color="primary" (click)="openDialog('save')" class="w-100">
                  <mat-icon>save</mat-icon>
                  Save As
                </button>
              </div>
            </div>
          </div>
          <button type="button" class="prop_dtls_closeBtn float-right" (click)="closeDealAnalysisSheet()">
            <img src="../../../../assets/images/close.svg" />
          </button>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-6">
          <div class="row propinfo_header_stick" id="propinfo_header" *ngIf="activePropertyDetails">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12" id="col1">
              <h5
                class="mb-0"
                (click)="openingPropertySheet()"
                *ngIf="activePropertyDetails.propertyAddress"
                [propertyAddress]="activePropertyDetails.Address"
              >
                {{ activePropertyDetails.propertyAddress || activePropertyDetails.StreetAddress | IsValueExist }}
              </h5>
              <h6
                (click)="openingPropertySheet()"
                class="mb-0 d-inline-block"
                *ngIf="activePropertyDetails.City"
                [propertyAddress]="activePropertyDetails.Address"
              >
                {{ activePropertyDetails.City ? activePropertyDetails.City : '' }}
                {{ activePropertyDetails.StateOrProvince ? ', ' + activePropertyDetails.StateOrProvince : '' }}
                {{ activePropertyDetails.PostalCode ? ' ' + activePropertyDetails.PostalCode : '' }}
              </h6>
              <p class="inline-block badge alert-info mr-2 ml-2 mb-0">{{ activePropertyDetails.PropertySubType }}</p>
              <br />
              <div class="status inline-block" *ngIf="activePropertyDetails.DaysOnMarket">
                On Market for
                <b>{{ activePropertyDetails.DaysOnMarket }} Days</b>
              </div>
            </div>

            <hr />
          </div>
        </div>
        <div class="col-6">
          <ul class="prop_highlight pl-2 mb-0 float-left mr-0">
            <li [matTooltip]="'Bed'">
              <img src="../../assets/images/bed.svg" width="20" />
              <p class="ipad_amn_break ml-1">
                {{ activePropertyDetails?.BedroomsTotal | number: '1.0-0' | IsValueExist }}
              </p>
            </li>
            <li [matTooltip]="'Bath'">
              <img src="../../assets/images/bath.svg" width="20" />
              <p class="ipad_amn_break ml-1">
                {{ activePropertyDetails?.BathroomsTotalInteger | number: '1.0-0' | IsValueExist }}
              </p>
            </li>
            <li [matTooltip]="'Living Area'">
              <img src="../../assets/images/living_area.svg" width="20" />
              <p class="ipad_amn_break ml-1">
                {{ activePropertyDetails?.LivingArea | number: '1.0-0' | IsValueExist }}<small> Sq.ft</small>
              </p>
            </li>
            <li [matTooltip]="'Lot Size Square Feet'">
              <img src="../../assets/images/land_area.svg" width="20" />
              <p
                class="ipad_amn_break ml-1"
                [innerHTML]="activePropertyDetails?.LotSizeSquareFeet | SqftToAcresConverter"
              ></p>
            </li>
          </ul>
          <div class="col-lg-8 col-md-8 col-sm-6 col-12 rate_sec text-right" id="col2">
            <div id="propdtls_val_sec" class="d-inline-block float-md-right float-left">
              <div class="pr-2 pl-2 float-right">
                <small>Rent Value</small><br />
                <h4>
                  <span
                    ><b>{{ activePropertyDetails?.RentValue | CurrencyFormatter | IsValueExist }}</b></span
                  >
                </h4>
              </div>
              <div class="pr-2 pl-0 pl-sm-2 float-right">
                <small>Current Value</small><br />
                <h4 class="mb-0">
                  <b
                    *ngIf="
                      (activeAVMHistory && activeAVMHistory.length > 0) || activeHomeValue?.HomeValue;
                      else noActiveAVMHistory
                    "
                    [innerHTML]="
                      activeHomeValue?.HomeValue || activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist
                    "
                  ></b>
                  <ng-template #noActiveAVMHistory>
                    <b>--</b>
                  </ng-template>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="row">
                <div class="col-12">
                  <h5 class="mb-0">Income Valuation</h5>
                </div>
                <div class="col-6" *ngIf="!showIncomeDetails">
                  <button mat-icon-button (click)="toggleIncomeDetails()">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="showIncomeDetails">
            <div class="col-6">
              Cap Rate
              <td>
                {{
                  propertyInfo?.CapRate || activePropertyDetails?.CapRate
                    ? (propertyInfo?.CapRate || activePropertyDetails?.CapRate) + '%'
                    : '--'
                }}
              </td>
            </div>

            <div class="col-6">
              Cash On Cash
              <td *ngIf="computedDealAnalysisResult && computedDealAnalysisResult.cashOnCashReturnRatio">
                {{
                  computedDealAnalysisResult.cashOnCashReturnRatio.length > 0
                    ? (computedDealAnalysisResult.cashOnCashReturnRatio[0] * 100).toFixed(2) + '%'
                    : '--'
                }}
              </td>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="table-responsive">
                <table class="mtable">
                  <tr>
                    <td class="p-0 table-scroll">
                      <table class="border1 tab-head">
                        <tr>
                          <th class="w-100">&nbsp;</th>
                        </tr>
                        <tr>
                          <td>Rental Income</td>
                        </tr>
                        <tr>
                          <td>Expense</td>
                        </tr>
                        <tr>
                          <td>Cash Flow</td>
                        </tr>
                      </table>
                    </td>
                    <td class="p-0">
                      <table class="w-100 data-tab border1">
                        <tr>
                          <th>Per Month</th>
                        </tr>
                        <tr>
                          <td
                            *ngIf="
                              computedDealAnalysisResult &&
                              computedDealAnalysisResult.monthlyRent &&
                              computedDealAnalysisResult.monthlyRent[0]
                            "
                          >
                            {{
                              !isNumber(computedDealAnalysisResult.monthlyRent[0])
                                ? computedDealAnalysisResult.monthlyRent[0]
                                : (computedDealAnalysisResult.monthlyRent[0] | currency: 'USD':'symbol':'1.0-0')
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            *ngIf="
                              computedDealAnalysisResult &&
                              computedDealAnalysisResult.operatingExpense &&
                              computedDealAnalysisResult.operatingExpense[0]
                            "
                          >
                            {{
                              !isNumber(computedDealAnalysisResult.operatingExpense[0])
                                ? computedDealAnalysisResult.operatingExpense[0]
                                : (computedDealAnalysisResult.operatingExpense[0] / 12
                                  | currency: 'USD':'symbol':'1.0-0')
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            *ngIf="
                              computedDealAnalysisResult &&
                              computedDealAnalysisResult.cashFlow &&
                              computedDealAnalysisResult.cashFlow[0]
                            "
                          >
                            {{
                              !isNumber(computedDealAnalysisResult.cashFlow[0])
                                ? computedDealAnalysisResult.cashFlow[0]
                                : (computedDealAnalysisResult.cashFlow[0] / 12 | currency: 'USD':'symbol':'1.0-0')
                            }}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td class="p-0">
                      <table class="w-100 data-tab border1">
                        <tr>
                          <th>Per Year</th>
                        </tr>
                        <tr>
                          <td
                            *ngIf="
                              computedDealAnalysisResult &&
                              computedDealAnalysisResult.monthlyRent &&
                              computedDealAnalysisResult.monthlyRent[0]
                            "
                          >
                            {{
                              !isNumber(computedDealAnalysisResult.monthlyRent[0])
                                ? computedDealAnalysisResult.monthlyRent[0]
                                : (computedDealAnalysisResult.monthlyRent[0] * 12 | currency: 'USD':'symbol':'1.0-0')
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            *ngIf="
                              computedDealAnalysisResult &&
                              computedDealAnalysisResult.operatingExpense &&
                              computedDealAnalysisResult.operatingExpense[0]
                            "
                          >
                            {{
                              !isNumber(computedDealAnalysisResult.operatingExpense[0])
                                ? computedDealAnalysisResult.operatingExpense[0]
                                : (computedDealAnalysisResult.operatingExpense[0] | currency: 'USD':'symbol':'1.0-0')
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            *ngIf="
                              computedDealAnalysisResult &&
                              computedDealAnalysisResult.cashFlow &&
                              computedDealAnalysisResult.cashFlow[0]
                            "
                          >
                            {{
                              !isNumber(computedDealAnalysisResult.cashFlow[0])
                                ? computedDealAnalysisResult.cashFlow[0]
                                : (computedDealAnalysisResult.cashFlow[0] | currency: 'USD':'symbol':'1.0-0')
                            }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="line-chart return-indicator my-3" *ngIf="returnIndicatorSummary && returnIndicatorSettings">
              <app-line-chart [chartData]="returnIndicatorSummary" [chartSettings]="returnIndicatorSettings">
              </app-line-chart>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <div class="col-6">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="row">
                <div class="col-12">
                  <h5 class="mb-0">Equity Valuation</h5>
                </div>
                <div class="col-6" *ngIf="!showEquityDetails">
                  <button mat-icon-button (click)="toggleEquityDetails()">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="showEquityDetails">
            <div class="col-6">
              Return On Equity
              <td>
                {{ !isNumber(returnOnEquityRatio) ? '--' : (returnOnEquityRatio * 100).toFixed(2) + '%' }}
              </td>
            </div>
            <div class="col-6">
              Achieve Profit On Sale by
              <td>{{ profitOnSaleYear }} Years</td>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              Equity After {{ profitOnSaleYear }}
              Years
              <td>
                {{ !isNumber(equity) ? equity : (equity | currency: 'USD':'symbol':'1.0-0') }}
              </td>
            </div>
            <div class="col-6">
              Profit On Sale After {{ profitOnSaleYear }}
              Years
              <td>
                {{ !isNumber(profitOnSale) ? profitOnSale : (profitOnSale | currency: 'USD':'symbol':'1.0-0') }}
              </td>
            </div>
          </div>

          <div class="row">
            <div class="line-chart sale-prediction my-3" *ngIf="salePredictionSummary && salePredictionSettings">
              <app-line-chart [chartData]="salePredictionSummary" [chartSettings]="salePredictionSettings">
              </app-line-chart> c
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>

    <br />

    <div class="row">
      <form [formGroup]="dealAnalysisForm" class="left_stick">
        <div class="col-12">
          <h5 class="mb-0">Income Valuation</h5>
          <mat-tab-group>
            <mat-tab
              label="Purchase & Renovation
        "
            >
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.purchasePriceInput"
                    [matTooltipPosition]="'right'"
                    matTooltipClass="line-break"
                  >
                    help_outline</mat-icon
                  >
                  <label>Purchase Price:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uPurchasePrice" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uPurchasePrice?.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.downPaymentInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Down Payment:</label>
                </div>
                <div class="col-lg-5 col-12 downpayment form-field">
                  <input currencyMask formControlName="uDownPayment" />
                  <div class="select_wrap">
                    <select class="payment_type" formControlName="uDownPaymentType">
                      <option *ngFor="let downPaymentType of downPaymentTypeList" [ngValue]="downPaymentType">
                        {{ downPaymentType }}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="f?.uDownPayment.errors || downPaymentError" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.landValueInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Land Value:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uLandValue" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uLandValue.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.closingCostsInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Closing Costs:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uClosingCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uClosingCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.renovationCostsInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Renovation Costs:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uRenovationCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uRenovationCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-tab>
            <mat-tab
              label="Mortgage Information
      "
            >
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.loanAmountInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Loan Amount:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uLoanAmount" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uLoanAmount.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.interestRateInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Interest Rate:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uInterestRate" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uInterestRate.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.termInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Term:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uLoanTerm" /><span class="unit">Yrs</span>
                </div>
                <div *ngIf="f?.uLoanTerm.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.monthlyMortgagePaymentInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Monthly Mortgage Payment:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uMonthlyMortgagePayment" /><span class="unit"
                    >$</span
                  >
                </div>
              </div>
            </mat-tab>
            <mat-tab
              label="Annual Expenses
    "
            >
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Property Taxes:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uPropertyTaxAmount" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uPropertyTaxAmount.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Insurance:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uInsuranceAmount" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uInsuranceAmount.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.hoaFeeInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>HOA Fee:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uHoaFee" /><span class="unit">$</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uHoaFeeYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uHoaFeeYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Property Management:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uPropertyManagementPercent" /><span class="unit"
                    >%</span
                  >
                </div>
                <div *ngIf="f?.uPropertyManagementPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Repairs & Maintenance:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uMaintenancePercent" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uMaintenancePercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.improvementsInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Improvements:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uImprovementPercent" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uImprovementPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Warranty Service:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uWarrantyServiceCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uWarrantyServiceCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uWarrantyServiceYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uWarrantyServiceYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Accounting:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uAccountingCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uAccountingCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uAccountingYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uAccountingYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-tab>
            <mat-tab
              label="Revenue
  "
            >
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Monthly Rent:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uMonthlyRent" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uMonthlyRent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uMonthlyRentYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uMonthlyRentYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.rentChurnInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Renter Churn:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uRenterChurn" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uRenterChurn.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.newTenantSearchTimeInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>New Tenant Search Time:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uTenantSearchDuration" /><span class="unit"
                    >Months</span
                  >
                </div>
                <div *ngIf="f?.uTenantSearchDuration.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-tab>
            <mat-tab
              label="Value
"
            >
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.assessedPropertyValueInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Assessed Property Value:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uAssessedPropertyValue" /><span class="unit"
                    >$</span
                  >
                </div>
                <div *ngIf="f?.uAssessedPropertyValue.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input
                      matInput
                      type="number"
                      class="input-yoy"
                      formControlName="uAssessedPropertyValueYoYPercent"
                    />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uAssessedPropertyValueYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.fairMarketValueInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Fair Market Value:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uFairMarketValue" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uFairMarketValue.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uFairMarketValueYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uFairMarketValueYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-tab>
            <mat-tab
              label="Sale
"
            >
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.salesCommissionInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Sales Commission:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uSalesCommissionPercent" /><span class="unit"
                    >%</span
                  >
                </div>
                <div *ngIf="f?.uSalesCommissionPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </form>
    </div>

    <mat-sidenav-content class="section-middle">
      <div class="section-data mt-0">
        <mat-card class="p-2 py-4 px-3">
          <table class="data-table table-header">
            <tr>
              <td></td>
              <td
                [class.expanded-col]="!dealAnalysisSheetFacade.defaultYearList.includes(year)"
                *ngFor="let year of dealAnalysisSheetFacade.displayYearList$ | async"
              >
                <span
                  class="btn-year btn-expand"
                  [class.shrink]="isExpanded10"
                  *ngIf="year == 10 || year == 6"
                  (click)="toggleYear(10, !isExpanded10)"
                >
                  <mat-icon>arrow_left</mat-icon>
                  <mat-icon>arrow_right</mat-icon>
                </span>
                <span
                  class="btn-year btn-expand"
                  [class.shrink]="isExpanded15"
                  *ngIf="year == 15 || year == 11"
                  (click)="toggleYear(15, !isExpanded15)"
                >
                  <mat-icon>arrow_left</mat-icon>
                  <mat-icon>arrow_right</mat-icon>
                </span>
                <span
                  class="btn-year btn-expand"
                  [class.shrink]="isExpanded20"
                  *ngIf="year == 20 || year == 16"
                  (click)="toggleYear(20, !isExpanded20)"
                >
                  <mat-icon>arrow_left</mat-icon>
                  <mat-icon>arrow_right</mat-icon>
                </span>
                <span
                  class="btn-year btn-expand"
                  [class.shrink]="isExpanded30"
                  *ngIf="year == 30 || year == 21"
                  (click)="toggleYear(30, !isExpanded30)"
                >
                  <mat-icon>arrow_left</mat-icon>
                  <mat-icon>arrow_right</mat-icon>
                </span>
                Year {{ year }}
              </td>
            </tr>
          </table>

          <mat-accordion class="compute-table" [multi]="true">
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> Revenue </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.grossRentInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Gross Rent
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.grossRent">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Monthly Rent
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.monthlyRent">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.vacancyInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Vacancy
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.vacancy">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Operating Income
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.operatingIncome"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> Expenses </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Property Taxes
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.propertyTax">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Insurance
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.insurance">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.hoaFeeInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        HOA Fee
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.hoaFee">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Property Management
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.propertyManagement">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Repairs & Maintenance
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.maintenance">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Warranty Service
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.warrantyService">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Accounting
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.accounting">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Operating Expenses
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.operatingExpense"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> Cash Flow </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Operating Income
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.operatingIncome">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Operating Expenses
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.netOperatingIncomeInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Net Operating Income
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.netOperatingIncome">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>Mortgage Payment</td>
                      <td *ngFor="let data of computedDealAnalysisResult.mortgagePayment">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.cashFlowInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Cash Flow
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.cashFlow"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> Tax Deductions </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Operating Expenses
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.mortgageInterestInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Mortgage Interest
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.mortgageInterest">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.depreciationInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Depreciation
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.depreciation">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.taxDeductionInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Total Deductions
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.totalDeduction"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>Taxable Income</td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.taxableIncome"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> Equity Appreciation </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.propertyValueInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Property Value
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.propertyValue">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>Mortgage Balance</td>
                      <td *ngFor="let data of computedDealAnalysisResult.mortgageBalance">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Your Equity
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.equity"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> When Should I Sell? </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Your Equity
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.equity">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.sellingCostsInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Selling Costs
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.sellingCost">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.cumulativeCashFlowInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Cumulative Cash Flow
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.cumulativeCashFlow">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.totalCashInvestedInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Total Cash Invested
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.totalCashInvested">
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.netProceedsInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Net Proceeds
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.netProceed"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.profitOnSaleInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Profit On Sale
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.profitOnSale"
                      >
                        {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="compute-section">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> Return Indicators </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnPurchasePriceInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Cap Rate on Purchase Price
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.capRateOnPurchasePriceRatio">
                        {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                      </td></ng-container
                    >
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnFMVInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Cap Rate on FMV
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.capRateOnFMVRatio">
                        {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                      </td></ng-container
                    >
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.cashOnCashReturnInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Cash on Cash Return
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.cashOnCashReturnRatio">
                        {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                      </td></ng-container
                    >
                  </tr>
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.returnOnInvestmentInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Return on Investment
                      </td>
                      <td *ngFor="let data of computedDealAnalysisResult.returnOnInvestmentRatio">
                        {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                      </td></ng-container
                    >
                  </tr>
                </table>
              </mat-expansion-panel>
              <div class="group-footer">
                <table class="data-table">
                  <tr>
                    <ng-container>
                      <td>
                        <mat-icon
                          [matTooltip]="dealAnalysisConfig.tooltipText.returnOnEquityInput"
                          [matTooltipPosition]="'right'"
                          matTooltipClass="line-break"
                        >
                          help_outline</mat-icon
                        >
                        Return on Equity
                      </td>
                      <td
                        [class.text-danger]="isNumber(data) && data < 0"
                        *ngFor="let data of computedDealAnalysisResult.returnOnEquityRatio"
                      >
                        {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                      </td></ng-container
                    >
                  </tr>
                </table>
              </div>
            </div>
          </mat-accordion>
        </mat-card>
      </div>
    </mat-sidenav-content> -->
    <!-- Arya's code end -->

    <!-- <mat-sidenav-container>
      <mat-sidenav
        #leftSidenav
        mode="side"
        class="section-left"
        fixedInViewport="true"
        position="start"
        fixedTopGap="58"
        disableClose
        [ngClass]="[leftSidenav.opened ? 'elevation-high' : 'elevation-low']"
      >
        <div class="btn-expand-right" (click)="leftSidenav.toggle()">
          <mat-icon *ngIf="leftSidenav.opened">chevron_left</mat-icon>
          <mat-icon *ngIf="!leftSidenav.opened">chevron_right</mat-icon>
        </div>
        <form [formGroup]="dealAnalysisForm" class="left_stick">
          <mat-accordion class="dealAnalysisSection">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="col-12">
                    <h5 class="my-0">Purchase & Renovation</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.purchasePriceInput"
                    [matTooltipPosition]="'right'"
                    matTooltipClass="line-break"
                  >
                    help_outline</mat-icon
                  >
                  <label>Purchase Price:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uPurchasePrice" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uPurchasePrice?.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.downPaymentInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Down Payment:</label>
                </div>
                <div class="col-lg-5 col-12 downpayment form-field">
                  <input currencyMask formControlName="uDownPayment" />
                  <div class="select_wrap">
                    <select class="payment_type" formControlName="uDownPaymentType">
                      <option *ngFor="let downPaymentType of downPaymentTypeList" [ngValue]="downPaymentType">
                        {{ downPaymentType }}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="f?.uDownPayment.errors || downPaymentError" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.landValueInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Land Value:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uLandValue" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uLandValue.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.closingCostsInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Closing Costs:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uClosingCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uClosingCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.renovationCostsInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Renovation Costs:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uRenovationCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uRenovationCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="dealAnalysisSection">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="col-12">
                    <h5>Mortgage Information</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.loanAmountInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Loan Amount:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uLoanAmount" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uLoanAmount.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.interestRateInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Interest Rate:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uInterestRate" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uInterestRate.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.termInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Term:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uLoanTerm" /><span class="unit">Yrs</span>
                </div>
                <div *ngIf="f?.uLoanTerm.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.monthlyMortgagePaymentInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Monthly Mortgage Payment:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uMonthlyMortgagePayment" /><span class="unit"
                    >$</span
                  >
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="dealAnalysisSection">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="col-12">
                    <h5>Annual Expenses</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Property Taxes:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uPropertyTaxAmount" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uPropertyTaxAmount.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Insurance:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uInsuranceAmount" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uInsuranceAmount.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.hoaFeeInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>HOA Fee:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uHoaFee" /><span class="unit">$</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uHoaFeeYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uHoaFeeYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Property Management:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uPropertyManagementPercent" /><span class="unit"
                    >%</span
                  >
                </div>
                <div *ngIf="f?.uPropertyManagementPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Repairs & Maintenance:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uMaintenancePercent" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uMaintenancePercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.improvementsInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Improvements:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uImprovementPercent" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uImprovementPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Warranty Service:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uWarrantyServiceCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uWarrantyServiceCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uWarrantyServiceYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uWarrantyServiceYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Accounting:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uAccountingCost" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uAccountingCost.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uAccountingYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uAccountingYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="dealAnalysisSection">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="col-12">
                    <h5>Revenue</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Monthly Rent:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uMonthlyRent" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uMonthlyRent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uMonthlyRentYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uMonthlyRentYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.rentChurnInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Renter Churn:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uRenterChurn" /><span class="unit">%</span>
                </div>
                <div *ngIf="f?.uRenterChurn.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.newTenantSearchTimeInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>New Tenant Search Time:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uTenantSearchDuration" /><span class="unit"
                    >Months</span
                  >
                </div>
                <div *ngIf="f?.uTenantSearchDuration.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="dealAnalysisSection">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="col-12">
                    <h5>Value</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.assessedPropertyValueInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Assessed Property Value:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uAssessedPropertyValue" /><span class="unit"
                    >$</span
                  >
                </div>
                <div *ngIf="f?.uAssessedPropertyValue.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input
                      matInput
                      type="number"
                      class="input-yoy"
                      formControlName="uAssessedPropertyValueYoYPercent"
                    />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uAssessedPropertyValueYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.fairMarketValueInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Fair Market Value:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" currencyMask formControlName="uFairMarketValue" /><span class="unit">$</span>
                </div>
                <div *ngIf="f?.uFairMarketValue.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-12 form-label"></div>
                <div class="col-lg-5 col-12 form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>YoY %</mat-label>
                    <input matInput type="number" class="input-yoy" formControlName="uFairMarketValueYoYPercent" />
                  </mat-form-field>
                </div>
                <div *ngIf="f?.uFairMarketValueYoYPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="dealAnalysisSection">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="col-12">
                    <h5>Sale</h5>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-lg-7 col-12 form-label">
                  <mat-icon
                    [matTooltip]="dealAnalysisConfig.tooltipText.salesCommissionInput"
                    matTooltipClass="line-break"
                    [matTooltipPosition]="'right'"
                    >help_outline</mat-icon
                  >
                  <label>Sales Commission:</label>
                </div>
                <div class="col-lg-5 col-12 form-field">
                  <input class="pl-3" type="number" formControlName="uSalesCommissionPercent" /><span class="unit"
                    >%</span
                  >
                </div>
                <div *ngIf="f?.uSalesCommissionPercent.errors" class="errMsg-alert text-right">
                  {{ errorMessage }}
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </form>
      </mat-sidenav>

      <mat-sidenav
        #rightSidenav
        mode="side"
        class="section-right"
        position="end"
        fixedInViewport="true"
        fixedTopGap="58"
        disableClose
        [ngClass]="rightSidenav.opened ? 'elevation-highR' : 'elevation-lowR'"
      >
        <div class="btn-expand-left" (click)="rightSidenav.toggle()">
          <mat-icon *ngIf="rightSidenav.opened">chevron_right</mat-icon>
          <mat-icon *ngIf="!rightSidenav.opened">chevron_left</mat-icon>
        </div>
        <div class="section-visualize">
          <div class="line-chart sale-prediction my-3" *ngIf="salePredictionSummary && salePredictionSettings">
            <app-line-chart [chartData]="salePredictionSummary" [chartSettings]="salePredictionSettings">
            </app-line-chart>
          </div>
          <hr />
          <div class="line-chart return-indicator my-3" *ngIf="returnIndicatorSummary && returnIndicatorSettings">
            <app-line-chart [chartData]="returnIndicatorSummary" [chartSettings]="returnIndicatorSettings">
            </app-line-chart>
          </div>
          <hr />
          <div *ngIf="isPieChartVisible" class="pie-chart income-expense">
            <div class="row mx-2">
              <div class="col-6">
                <mat-radio-group
                  aria-label="Select a view"
                  (change)="onViewChange($event)"
                  #radioGroup="matRadioGroup"
                  class="py-1 d-inline-block"
                >
                  <mat-radio-button
                    value="income"
                    class="mb-1 mr-2"
                    [disabled]="isIncomePieDisabled"
                    [checked]="isIncomePieChecked"
                  >
                    Income</mat-radio-button
                  >
                  <mat-radio-button
                    value="expense"
                    [disabled]="isExpensePieDisabled"
                    [checked]="!isIncomePieChecked"
                    style="margin-top: 5px"
                    >Expense</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <div class="col-6 text-right pt-1">
                <mat-label class="mb-2"> Select Year </mat-label>
                <mat-select (selectionChange)="onYearChange($event)" [(ngModel)]="selectedYear">
                  <mat-option *ngFor="let year of dealAnalysisYearRange" [value]="year"> {{ year }} </mat-option>
                </mat-select>
              </div>
            </div>
            <app-pie-chart *ngIf="selectedPieChartOption === 'income'" [pieChartConfig]="incomePieChartConfig">
            </app-pie-chart>
            <app-pie-chart *ngIf="selectedPieChartOption === 'expense'" [pieChartConfig]="expensePieChartConfig">
            </app-pie-chart> 
             
          </div>
        </div>
      </mat-sidenav>

      <mat-sidenav-content class="section-middle">
        <div class="singleDetails">
          <div #spacer></div>
          <div class="transition is-stickyActive scroller1">
            <div class="row propinfo_header_stick" id="propinfo_header" *ngIf="activePropertyDetails">
              <div class="col-lg-4 col-md-4 col-sm-6 col-12" id="col1">
                <h5
                  class="mb-0"
                  (click)="openingPropertySheet()"
                  *ngIf="activePropertyDetails.propertyAddress"
                  [propertyAddress]="activePropertyDetails.Address"
                >
                  {{ activePropertyDetails.propertyAddress || activePropertyDetails.StreetAddress | IsValueExist }}
                </h5>
                <h6
                  (click)="openingPropertySheet()"
                  class="mb-0 d-inline-block"
                  *ngIf="activePropertyDetails.City"
                  [propertyAddress]="activePropertyDetails.Address"
                >
                  {{ activePropertyDetails.City ? activePropertyDetails.City : '' }}
                  {{ activePropertyDetails.StateOrProvince ? ', ' + activePropertyDetails.StateOrProvince : '' }}
                  {{ activePropertyDetails.PostalCode ? ' ' + activePropertyDetails.PostalCode : '' }}
                </h6>
                <p class="inline-block badge alert-info mr-2 ml-2 mb-0">{{ activePropertyDetails.PropertySubType }}</p>
                <br />
                <div class="status inline-block" *ngIf="activePropertyDetails.DaysOnMarket">
                  On Market for
                  <b>{{ activePropertyDetails.DaysOnMarket }} Days</b>
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-6 col-12 rate_sec text-right" id="col2">
                <div id="propdtls_val_sec" class="d-inline-block float-md-right float-left">
                  <div class="pr-2 pl-2 float-right">
                    <small>Rent Value</small><br />
                    <h4>
                      <span
                        ><b>{{ activePropertyDetails.RentValue | CurrencyFormatter | IsValueExist }}</b></span
                      >
                    </h4>
                  </div>
                  <div class="pr-2 pl-0 pl-sm-2 float-right">
                    <small>Current Value</small><br />
                    <h4 class="mb-0">
                      <b
                        *ngIf="
                          (activeAVMHistory && activeAVMHistory.length > 0) || activeHomeValue?.HomeValue;
                          else noActiveAVMHistory
                        "
                        [innerHTML]="
                          activeHomeValue?.HomeValue || activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist
                        "
                      ></b>
                      <ng-template #noActiveAVMHistory>
                        <b>--</b>
                      </ng-template>
                    </h4>
                  </div>
                </div>
                <ul class="prop_highlight pl-2 mb-0 float-left float-md-right mr-0 mr-md-3">
                  <li [matTooltip]="'Bed'">
                    <img src="../../assets/images/bed.svg" width="20" />
                    <p class="ipad_amn_break ml-1">
                      {{ activePropertyDetails.BedroomsTotal | number: '1.0-0' | IsValueExist }}
                    </p>
                  </li>
                  <li [matTooltip]="'Bath'">
                    <img src="../../assets/images/bath.svg" width="20" />
                    <p class="ipad_amn_break ml-1">
                      {{ activePropertyDetails.BathroomsTotalInteger | number: '1.0-0' | IsValueExist }}
                    </p>
                  </li>
                  <li [matTooltip]="'Living Area'">
                    <img src="../../assets/images/living_area.svg" width="20" />
                    <p class="ipad_amn_break ml-1">
                      {{ activePropertyDetails.LivingArea | number: '1.0-0' | IsValueExist }}<small> Sq.ft</small>
                    </p>
                  </li>
                  <li [matTooltip]="'Lot Size Square Feet'">
                    <img src="../../assets/images/land_area.svg" width="20" />
                    <p
                      class="ipad_amn_break ml-1"
                      [innerHTML]="activePropertyDetails.LotSizeSquareFeet | SqftToAcresConverter"
                    ></p>
                  </li>
                </ul>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="section-data mt-0">
          <mat-card class="p-2 py-4 px-3">
            <table class="data-table table-header">
              <tr>
                <td></td>
                <td
                  [class.expanded-col]="!dealAnalysisSheetFacade.defaultYearList.includes(year)"
                  *ngFor="let year of dealAnalysisSheetFacade.displayYearList$ | async"
                >
                  <span
                    class="btn-year btn-expand"
                    [class.shrink]="isExpanded10"
                    *ngIf="year == 10 || year == 6"
                    (click)="toggleYear(10, !isExpanded10)"
                  >
                    <mat-icon>arrow_left</mat-icon>
                    <mat-icon>arrow_right</mat-icon>
                  </span>
                  <span
                    class="btn-year btn-expand"
                    [class.shrink]="isExpanded15"
                    *ngIf="year == 15 || year == 11"
                    (click)="toggleYear(15, !isExpanded15)"
                  >
                    <mat-icon>arrow_left</mat-icon>
                    <mat-icon>arrow_right</mat-icon>
                  </span>
                  <span
                    class="btn-year btn-expand"
                    [class.shrink]="isExpanded20"
                    *ngIf="year == 20 || year == 16"
                    (click)="toggleYear(20, !isExpanded20)"
                  >
                    <mat-icon>arrow_left</mat-icon>
                    <mat-icon>arrow_right</mat-icon>
                  </span>
                  <span
                    class="btn-year btn-expand"
                    [class.shrink]="isExpanded30"
                    *ngIf="year == 30 || year == 21"
                    (click)="toggleYear(30, !isExpanded30)"
                  >
                    <mat-icon>arrow_left</mat-icon>
                    <mat-icon>arrow_right</mat-icon>
                  </span>
                  Year {{ year }}
                </td>
              </tr>
            </table>

            <mat-accordion class="compute-table" [multi]="true">
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Revenue </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.grossRentInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Gross Rent
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.grossRent">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.monthlyRentInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Monthly Rent
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.monthlyRent">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.vacancyInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Vacancy
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.vacancy">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Operating Income
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.operatingIncome"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Expenses </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.propertyTaxesInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Property Taxes
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.propertyTax">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.insuranceInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Insurance
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.insurance">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.hoaFeeInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          HOA Fee
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.hoaFee">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.propertyManagementInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Property Management
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.propertyManagement">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.repairsAndMaintenanceInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Repairs & Maintenance
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.maintenance">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.warrantyServiceInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Warranty Service
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.warrantyService">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.accountingInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Accounting
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.accounting">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Operating Expenses
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.operatingExpense"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Cash Flow </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.operatingIncomeInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Operating Income
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.operatingIncome">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Operating Expenses
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.netOperatingIncomeInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Net Operating Income
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.netOperatingIncome">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                        
                          Mortgage Payment
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.mortgagePayment">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.cashFlowInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Cash Flow
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.cashFlow"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Tax Deductions </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.operatingExpenseInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Operating Expenses
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.operatingExpense">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.mortgageInterestInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Mortgage Interest
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.mortgageInterest">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.depreciationInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Depreciation
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.depreciation">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.taxDeductionInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Total Deductions
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.totalDeduction"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          
                          Taxable Income
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.taxableIncome"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Equity Appreciation </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.propertyValueInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Property Value
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.propertyValue">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                        
                          Mortgage Balance
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.mortgageBalance">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Your Equity
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.equity"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> When Should I Sell? </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.yourEquityInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Your Equity
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.equity">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.sellingCostsInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Selling Costs
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.sellingCost">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.cumulativeCashFlowInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Cumulative Cash Flow
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.cumulativeCashFlow">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.totalCashInvestedInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Total Cash Invested
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.totalCashInvested">
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.netProceedsInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Net Proceeds
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.netProceed"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.profitOnSaleInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Profit On Sale
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.profitOnSale"
                        >
                          {{ !isNumber(data) ? data : (data | currency: 'USD':'symbol':'1.0-0') }}
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="compute-section">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Return Indicators </mat-panel-title>
                  </mat-expansion-panel-header>
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnPurchasePriceInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Cap Rate on Purchase Price
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.capRateOnPurchasePriceRatio">
                          {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                        </td></ng-container
                      >
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.capRateOnFMVInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Cap Rate on FMV
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.capRateOnFMVRatio">
                          {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                        </td></ng-container
                      >
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.cashOnCashReturnInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Cash on Cash Return
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.cashOnCashReturnRatio">
                          {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                        </td></ng-container
                      >
                    </tr>
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.returnOnInvestmentInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Return on Investment
                        </td>
                        <td *ngFor="let data of computedDealAnalysisResult.returnOnInvestmentRatio">
                          {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                        </td></ng-container
                      >
                    </tr>
                  </table>
                </mat-expansion-panel>
                <div class="group-footer">
                  <table class="data-table">
                    <tr>
                      <ng-container>
                        <td>
                          <mat-icon
                            [matTooltip]="dealAnalysisConfig.tooltipText.returnOnEquityInput"
                            [matTooltipPosition]="'right'"
                            matTooltipClass="line-break"
                          >
                            help_outline</mat-icon
                          >
                          Return on Equity
                        </td>
                        <td
                          [class.text-danger]="isNumber(data) && data < 0"
                          *ngFor="let data of computedDealAnalysisResult.returnOnEquityRatio"
                        >
                          {{ !isNumber(data) ? '--' : (data * 100).toFixed(2) + '%' }}
                        </td></ng-container
                      >
                    </tr>
                  </table>
                </div>
              </div>
            </mat-accordion>
          </mat-card>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container> -->
  </mat-sidenav>
</mat-sidenav-container>
